import { createGatsbyPageComponent } from '@shapeable/web';
import { graphql } from 'gatsby';

export default createGatsbyPageComponent('Page');

export const query = graphql`
  query PageQuery($id: ID!) {
    platform {
      page(id: $id) {
        id slug path name title pretitle subtitle
        content { text }
        openGraph { title description { plain } }
        intro { text }
        outro { text }
        orderNumber
        options { text }
        layout {
          id name slug component 
        }
        videos {
          id name url
          thumbnail { id url static { childImageSharp { gatsbyImageData } } }
        }
        trends {
          name slug path outlineNumber color { value }
        }
        people {
          id name slug path
          organisation { id name }
          position
          photo { id url }
        }
        embeds {
          citations {
            slug url name authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
          }
          pages {
            id name slug path
            openGraph {
              title description { plain }
              image { url static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } }
            }
          }
          people {
            id name slug path
            organisation { id name }
            position
            photo { id url }
          }
          imageAssets {
            id
            slug
            description { plain }
            alternateText
            image {
              id url width height static { childImageSharp { gatsbyImageData(placeholder: NONE) } } 
            }
          }
        }
        
        featurePages {
          __typename id name path slug title subtitle pretitle 
          openGraph { description { plain } }
          intro { text }
          orderNumber
          layout { name slug }
          videos {
            id name url
            thumbnail { id url static { childImageSharp { gatsbyImageData } } }
          }
          banner {
            name slug alternateText
            thumbnail { 
              id url width height static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } 
            }
          }
        }
        marqueePages {
          __typename id name path slug title subtitle pretitle 
          openGraph { description { plain } }
          intro { text }
          orderNumber
          videos {
            id name url
            thumbnail { id url static { childImageSharp { gatsbyImageData } } }
          }
          layout { name slug }
          banner {
            name slug alternateText
            thumbnail { 
              id url width height static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } 
            }
          }
        }
        children {
          __typename id name path slug title subtitle pretitle 
          openGraph { description { text } }
          intro { text }
          orderNumber
          banner {
            name slug alternateText
            thumbnail { 
              id url width height static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } 
            }
          }
        }
        banner {
          id name slug title description { text } alternateText
          image { 
            id url width height static { childImageSharp { gatsbyImageData(placeholder: NONE) } } 
          }
        }
        
      }
    }
  }
`;